{
  "$schema": "http://json.schemastore.org/package",
  "name": "verdaccio-github-oauth-ui",
  "version": "3.2.7",
  "keywords": [
    "authentication",
    "github",
    "login",
    "npm",
    "oauth",
    "package",
    "private",
    "registry",
    "verdaccio",
    "verdaccio-auth",
    "verdaccio-auth-plugin",
    "verdaccio-plugin"
  ],
  "homepage": "https://github.com/n4bb12/verdaccio-github-oauth-ui/blob/master/README.md",
  "bugs": "https://github.com/n4bb12/verdaccio-github-oauth-ui/issues",
  "repository": "github:n4bb12/verdaccio-github-oauth-ui",
  "license": "MIT",
  "author": "Abraham Schilling",
  "main": "dist/server/index.js",
  "bin": {
    "verdaccio-github-oauth-ui": "dist/cli/index.js"
  },
  "files": [
    "dist"
  ],
  "scripts": {
    "build": "bash run build",
    "clean": "bash run clean",
    "dev": "yarn update && yarn start",
    "fix": "yarn format && yarn typecheck",
    "format": "prettier --write \"**/*.{ts,js,css,scss,html,json}\"",
    "format:check": "prettier --check \"**/*.{ts,js,css,scss,html,json}\"",
    "prepack": "bash run clean && bash run build",
    "start": "verdaccio -c verdaccio.yaml",
    "test": "vitest --watch false",
    "test:cli": "bash run test-cli",
    "test:coverage": "vitest --coverage",
    "test:docker": "bash run test-docker",
    "test:publish": "bash run test-publish",
    "test:whoami": "bash run test-whoami",
    "typecheck": "bash run typecheck",
    "update": "yarn prepack && bash run copy",
    "watch": "nodemon --watch src --ext ts,css --exec \"yarn update\""
  },
  "dependencies": {
    "@octokit/oauth-methods": "^1.2.6",
    "@octokit/request": "^5.6.3",
    "chalk": "^4.1.1",
    "dotenv": "^16.0.1",
    "express": "^4.18.1",
    "global-agent": "^3.0.0",
    "lodash": "^4.17.21",
    "memory-cache": "^0.2.0",
    "minimist": "^1.2.6",
    "octokit": "^1.7.1",
    "open": "^8.0.7",
    "ow": "^0.28.1",
    "query-string": "^7.1.1"
  },
  "devDependencies": {
    "@parcel/config-default": "~2.4.0",
    "@parcel/transformer-typescript-tsc": "~2.4.0",
    "@types/express": "^4.17.9",
    "@types/global-agent": "^2.1.0",
    "@types/lodash": "^4.14.178",
    "@types/memory-cache": "^0.2.2",
    "@types/minimist": "^1.2.1",
    "@types/node": "^16.11.9",
    "@types/query-string": "^6.3.0",
    "@verdaccio/types": "^10.2.2",
    "c8": "^7.11.0",
    "core-js": "^3.21.0",
    "nodemon": "^2.0.6",
    "parcel": "~2.4.0",
    "prettier": "^2.5.1",
    "release-it": "^15.0.0",
    "timekeeper": "^2.2.0",
    "type-fest": "^2.11.1",
    "typescript": "^4.5.5",
    "verdaccio": "^5.6.2",
    "verdaccio-htpasswd": "^10.1.0",
    "vite": "^2.9.9",
    "vitest": "^0.12.9"
  },
  "peerDependencies": {
    "verdaccio": ">=5"
  },
  "engines": {
    "node": "^14||^16"
  },
  "publishConfig": {
    "registry": "https://registry.npmjs.org/"
  },
  "targets": {
    "client": {
      "source": "src/client/verdaccio-5.ts",
      "distDir": "dist/client",
      "context": "browser",
      "includeNodeModules": true
    },
    "server": {
      "source": "src/server/index.ts",
      "distDir": "dist/server",
      "context": "node",
      "outputFormat": "commonjs",
      "optimize": false,
      "isLibrary": true
    },
    "cli": {
      "source": "src/cli/index.ts",
      "distDir": "dist/cli",
      "context": "node",
      "outputFormat": "commonjs",
      "optimize": false
    }
  }
}
